window.$ = window.jQuery = require('jquery/dist/jquery.min');
require('bootstrap/dist/js/bootstrap.min');
require('popper.js/dist/popper.min');
require('uikit/dist/js/uikit.min');
require('uikit/dist/js/uikit-core.min');
require('uikit/dist/js/uikit-icons.min');
require('lightbox2/dist/js/lightbox.min');

// Photoswipe
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';


// Faces
const lightbox_faces = new PhotoSwipeLightbox({
    gallery: '#gallery--faces',
    children: 'a',
    pswpModule: () => import('photoswipe')
});
lightbox_faces.init();

// Paginacao
const lightbox_paginacao = new PhotoSwipeLightbox({
    gallery: '#gallery--paginacao',
    children: 'a',
    showHideAnimationType: 'fade',
    pswpModule: () => import('photoswipe')
});
lightbox_paginacao.init();

// Ambiente
const lightbox_ambiente = new PhotoSwipeLightbox({
    gallery: '#gallery--ambiente',
    children: 'a',
    showHideAnimationType: 'fade',
    pswpModule: () => import('photoswipe')
});
lightbox_ambiente.init();


// inicio
//Spinner Home
$("#loading1").on("click", function () {
    document.getElementsByClassName('box-load2')[0].style.display = 'flex';
    document.getElementsByClassName('content')[0].style.display = 'none';
});

// fim - click
//Spinner Home
$(".loading2").on("click", function () {
    document.getElementsByClassName('box-load2')[0].style.display = 'flex';
    document.getElementsByClassName('content')[0].style.display = 'none';
});

// --------------------------------------------------------Modal Email Contato------------------------------------------
$('#modalBusca').modal('show');


//
// let countrie2 = [];
// $.getJSON("https://www.villagres.com.br/api/PreviewSearch", function(datas) {
//     if (!("erro" in datas)) {
//         let jsonFromobj = JSON.stringify(datas);
//         let obj = JSON.parse(jsonFromobj)
//         // console.log(obj)
//         for(let i in obj){
//             countrie2  += obj[i];
//         }
//     }
// });
// console.log(countrie2);





// ----------------------------------------------------- auto complemtne busca --------------------------------------

if (document.getElementById("searchPro")){
    function autocomplete(inp, arr) {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function(e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists();
            if (!val) { return false;}
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function(e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists();
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function(e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) x[currentFocus].click();
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }

    //Api traz os nomes das buscas
    let countries = [];
    $.getJSON("https://www.villagres.com.br/api/PreviewSearch", function(datas) {
        if (!("erro" in datas)) {
            // Transforma os valores do objeto 'datas' em uma array e itera sobre ela
            Object.values(datas).forEach(function(valor) {
                let name = valor.nome;
                countries.push(name);
            });
        }
    });

    autocomplete(document.getElementById("searchPro"), countries);
}




